import {getMatDialogConfig} from '@/shared/utils/functions/mat-dialog';
import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {getEntityType} from '@datorama/akita';
import {Observable, of, switchMap} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {ShoppingCartService} from '../../../shopping-cart/state/shopping-cart.service';
import {SupplierProduct} from '../../state/supplier-product.model';
import {SupplierProductsQuery} from '../../state/supplier-products.query';
import {SupplierProductsService} from '../../state/supplier-products.service';
import {SupplierProductsState} from '../../state/supplier-products.store';
import {
  SupplierProductsWithSameBaseProductDialogData
} from '../dialogs/supplier-products-with-same-base-product-dialog/models/supplier-products-with-same-base-product-dialog-data.model';
import {
  SupplierProductsWithSameBaseProductDialogOutputData
} from '../dialogs/supplier-products-with-same-base-product-dialog/models/supplier-products-with-same-base-product-dialog-output-data.model';
import {
  SupplierProductsWithSameBaseProductDialogComponent
} from '../dialogs/supplier-products-with-same-base-product-dialog/supplier-products-with-same-base-product-dialog.component';

@Component({
  selector: 'app-supplier-product-gallery-item',
  templateUrl: './supplier-product-gallery-item.component.html',
  styleUrls: ['./supplier-product-gallery-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierProductGalleryItemComponent {
  readonly supplierProductsQuery = inject(SupplierProductsQuery);
  readonly supplierProductsService = inject(SupplierProductsService);

  private readonly matSnackBar = inject(MatSnackBar);
  private readonly matDialog = inject(MatDialog);
  private readonly shoppingCartService = inject(ShoppingCartService);

  @Input({required: true}) supplierProduct: SupplierProduct;

  @Input() rank: number = null;

  environment = environment;

  supplierProductsWithSameBaseProduct$: Observable<getEntityType<SupplierProductsState>[]>;

  ngOnInit() {
    this.supplierProductsWithSameBaseProduct$ = this.supplierProductsQuery.selectSupplierProductsWithSameBaseProduct(
      this.supplierProduct?.product_id
    );
  }

  toShoppingCart(event) {
    event.stopPropagation();

    this.supplierProductsWithSameBaseProduct$.pipe(
      take(1),
      switchMap(supplierProductsWithSameBaseProduct => {
        if (supplierProductsWithSameBaseProduct?.length <= 1) {
          this.shoppingCartService.addProductToCart(this.supplierProduct.id, 1);
          return of(this.supplierProduct);
        }

        const dialogConfig = getMatDialogConfig<SupplierProductsWithSameBaseProductDialogData>({
          data: {
            shopActive: environment.shopActive,
            supplierProductsWithSameBaseProduct
          },
        });

        return this.matDialog
          .open<
            SupplierProductsWithSameBaseProductDialogComponent,
            SupplierProductsWithSameBaseProductDialogData,
            SupplierProductsWithSameBaseProductDialogOutputData>(
            SupplierProductsWithSameBaseProductDialogComponent, dialogConfig
          )
          .afterClosed().pipe(
            map((result) => result?.supplierProduct)
          );
      }),
      tap(supplierProduct => {
        if (supplierProduct) {
          this.matSnackBar.open('Produkt wurde dem Warenkorb hinzugefügt!', null, {
            panelClass: 'snackbar-success',
          });
        }
      }),
    ).subscribe();
  }
}
