import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {createShoppingCartItem} from './shopping-cart.model';
import {ShoppingCartStore} from './shopping-cart.store';
import {SupplierProduct} from '../../supplier-product/state/supplier-product.model';
import {ShoppingCartQuery} from './shopping-cart.query';
import {ShopRouteNames} from '../../constants/routes.constants';
import {Router} from '@angular/router';
import {ShoppingCartRouteNames} from '../constants';

@Injectable({providedIn: 'root'})
export class ShoppingCartService {

  constructor(
    private shoppingCartStore: ShoppingCartStore,
    private shoppingCartQuery: ShoppingCartQuery,
    private router: Router) {
  }

  updateComments(comments: string) {
    return this.shoppingCartStore.update({comments});
  }

  updateApprovalWorkflowData(approvalWorkflowData: any) {
    return this.shoppingCartStore.update({approvalWorkflowData});
  }

  addProductToCart(supplierProductId: SupplierProduct['id'], quantity: number, setQuantity = false) {
    const shoppingCartItem = this.shoppingCartQuery.getEntity(supplierProductId);
    if (!!shoppingCartItem) {
      if (quantity > 0 && setQuantity) {
        return this.shoppingCartStore.updateQuantity(supplierProductId, quantity);
      } else if (quantity > 0) {
        return this.shoppingCartStore.updateQuantity(supplierProductId, shoppingCartItem.quantity + quantity);
      } else {
        return this.shoppingCartStore.remove(supplierProductId);
      }
    }
    if (quantity > 0) {
      const item = createShoppingCartItem({
        supplier_product_id: supplierProductId,
        quantity: quantity
      });

      return this.shoppingCartStore.add(item);
    }

    return null;
  }

  remove(supplierProductId: ID) {
    this.shoppingCartStore.remove(supplierProductId);
  }

  navigateToShoppingCart(): Promise<boolean> {
    return this.router.navigate([
      ShopRouteNames.SHOP +
      '/' +
      ShoppingCartRouteNames.SHOPPING_CART
    ]);
  }
}
