<div class="mt-4 welcome flex pl-[10%]">
    <div
      class="flex flex-col justify-center items-start flex-1 max-w-[50%]">
        <div class="mb-1 mat-headline">
            Willkommen auf der <span class="font-color-green">Einkaufsplattform</span><br/>
            der {{ sessionQuery.tenantName$ | async }}.
        </div>
        <span class="font-color-grey">
      Gemeinsam digitalisieren wir unseren Einkauf. Alle unsere Artikel für jede Einrichtung zum besten Preis verfügbar.
    </span>
        <div
          *ngIf="isSupplierProductProposalAllowed"
          class="ekp-green mt-6">
            <a
              mat-raised-button
              color="primary"
              (click)="navigateToSupplierProductProposal()">
                Jetzt Artikel vorschlagen
            </a>
        </div>
    </div>
    <div
      class="flex justify-center flex-1 max-w-[50%]">
        <img src="../../../../assets/add_to_cart.svg"/>
    </div>
</div>

<div class="logos flex justify-center mt-12">
    <img src="/assets/homepage_logos.svg"/>
</div>

<div
  *ngIf="(isLoading$ | async) === false"
  class="products mt-12">
    <h1 class="text-center">Neueste Artikel</h1>

    <ng-container *ngIf="(supplierProductsPagination$ | async) as supplierProductsPagination">
        <app-supplier-product-gallery
          *ngIf="supplierProductsPagination?.data?.length > 0; else supplierProductsLoading"
          [supplierProducts]="supplierProductsPagination.data">
        </app-supplier-product-gallery>

        <ng-template #supplierProductsLoading>
            <div class="mb-4">
                Es sind keine Artikel vorhanden.
            </div>
        </ng-template>
    </ng-container>
</div>

<div class="flex gap-[10%] contact mb-6 mt-10">
    <div class="flex justify-center w-[50%]">
        <img src="../../../../assets/team_spirit.svg"/>
    </div>
    <div class="flex flex-col justify-center items-start">
        <h1 class="mb-1">Wir sind für Sie da</h1>
        <h3 class="font-color-grey">
            Unser strategischer Einkauf steht jederzeit für Fragen und Unterstützung
            zur Verfügung.
        </h3>
        <div class="ekp-green">
            <a
              mat-button
              href="mailto:{{(sessionQuery.tenantSupportContactMail$ | async)}}"
              color="primary">
                E-Mail
            </a>
        </div>
    </div>
</div>
