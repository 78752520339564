import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {ShoppingCartItem} from './shopping-cart.model';
import {SupplierProduct} from '../../supplier-product/state/supplier-product.model';

export interface ShoppingCartState extends EntityState<ShoppingCartItem> {
  comments: string;
  approvalWorkflowData: any;
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'shoppingCart', idKey: 'supplier_product_id', resettable: true})
export class ShoppingCartStore extends EntityStore<ShoppingCartState> {

  constructor() {
    super({
      loading: false,
      comments: '',
      approvalWorkflowData: null
    });
  }

  updateQuantity(productId: SupplierProduct['id'], quantity: number) {
    this.update(productId, entity => {
      return {
        ...entity,
        quantity: quantity
      };
    });
  }

}
