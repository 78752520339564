import {SuppliersQuery} from '@/management/supplier/state/suppliers.query';
import {Injectable} from '@angular/core';
import {combineQueries, QueryEntity} from '@datorama/akita';
import {combineLatest} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay} from 'rxjs/operators';
import {calculateTotalPriceForSupplier} from '../../shared/helpers/calculate-total-price-for-supplier';
import {SupplierProductsQuery} from '../../supplier-product/state/supplier-products.query';
import {SupplierProductsService} from '../../supplier-product/state/supplier-products.service';
import {ShoppingCart} from './shopping-cart.model';
import {ShoppingCartState, ShoppingCartStore} from './shopping-cart.store';
import {groupShoppingCartItemsBySuppliers} from './utils/functions/group-shopping-cart-items-by-suppliers';
import {
  joinShoppingCartItemsWithSupplierProducts
} from './utils/functions/join-shopping-cart-items-with-supplier-products';

@Injectable({providedIn: 'root'})
export class ShoppingCartQuery extends QueryEntity<ShoppingCartState> {
  shoppingCartItems$ = combineLatest([
    this.selectAll(),
    this.supplierProductsQuery.selectAll({asObject: true})
  ]).pipe(
    map(joinShoppingCartItemsWithSupplierProducts),
    map(shoppingCartItems =>
      shoppingCartItems.filter(shoppingCartItem => {
        if (shoppingCartItem.product?.name && (shoppingCartItem?.unitPriceTotal >= 0 || shoppingCartItem?.billingFrequencyPriceTotal >= 0)) {
          return true;
        } else {
          this.supplierProductsService.getById(shoppingCartItem.supplier_product_id).subscribe();
          return false;
        }
      })),
    shareReplay({bufferSize: 1, refCount: true}),
  );

  shoppingCartItemsGroupBySuppliers$ = this.shoppingCartItems$.pipe(
    map(groupShoppingCartItemsBySuppliers),
    shareReplay({bufferSize: 1, refCount: true}),
  );

  shoppingCartItemsUnitPriceTotal$ = this.shoppingCartItemsGroupBySuppliers$.pipe(
    map(shoppingCartItemsGroupBySuppliers => shoppingCartItemsGroupBySuppliers?.length > 0
      ? shoppingCartItemsGroupBySuppliers
        .map(shoppingCartSupplier => shoppingCartSupplier?.unitPriceTotal)
        .reduce((accumulator, currentValue) => accumulator + currentValue)
      : null
    ),
  );

  shoppingCartItemsBillingFrequencyPriceTotal$ = this.shoppingCartItemsGroupBySuppliers$.pipe(
    map(shoppingCartItemsGroupBySuppliers => shoppingCartItemsGroupBySuppliers?.length > 0
      ? shoppingCartItemsGroupBySuppliers
        .map(shoppingCartSupplier => shoppingCartSupplier?.billingFrequencyPriceTotal)
        .reduce((accumulator, currentValue) => accumulator + currentValue)
      : null
    )
  );

  shoppingCart$ = combineQueries([
    this.shoppingCartItemsGroupBySuppliers$,
    this.shoppingCartItemsUnitPriceTotal$,
    this.shoppingCartItemsBillingFrequencyPriceTotal$,
    this.select('approvalWorkflowData'),
    this.select('comments'),
  ]).pipe(
    map(([
           shoppingCartItemsGroupBySuppliers,
           shoppingCartItemsUnitPriceTotal,
           shoppingCartItemsBillingFrequencyPriceTotal,
           approvalWorkflowData,
           comments,
         ]) => ({
      comments,
      approvalWorkflowData,
      shoppingCartItemsUnitPriceTotal,
      shoppingCartItemsBillingFrequencyPriceTotal,
      shoppingCartItemsGroupBySuppliers
    } as ShoppingCart))
  );

  shoppingCartApprovalWorkflowData$ = this.shoppingCart$.pipe(
    map((shoppingCart) => {
      if (shoppingCart?.approvalWorkflowData?.cost_center_id) {
        return shoppingCart.approvalWorkflowData;
      } else {
        return {
          cost_center_id: null
        };
      }
    }),
    distinctUntilChanged(),
    shareReplay({bufferSize: 1, refCount: true}),
  );

  shoppingCartTotalPrice$ = combineQueries([
    this.shoppingCartItemsGroupBySuppliers$,
    this.suppliersQuery.suppliers$,
  ]).pipe(
    filter(([, suppliers]) => suppliers?.length > 0),
    map(([shoppingCartItems, suppliers]) => {
      return shoppingCartItems.reduce((total, currentSupplier) => {
        const supplierForItem = suppliers.find(supplier => supplier.id === currentSupplier.supplier_id);
        return total + calculateTotalPriceForSupplier(supplierForItem, currentSupplier.unitPriceTotal);
      }, 0)
    }),
    shareReplay({bufferSize: 1, refCount: true}),
  );

  constructor(
    protected store: ShoppingCartStore,
    private supplierProductsQuery: SupplierProductsQuery,
    private supplierProductsService: SupplierProductsService,
    private suppliersQuery: SuppliersQuery,
  ) {
    super(store);
  }
}
