// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true, // TODO Change to "true" in prod environment
  stage: 'prod', // local, dev, staging, prod
  api: {
    baseUrl: 'https://api.4sourcing.de/api/v1/',
  },
  auth: {
    authority:
      'https://login.microsoftonline.com/organizations', // Only for usage with AzureAD // INIT Add OAuth 2.0 endpoint of your tenant ID (e. g. 'https://login.microsoftonline.com/organizations')
    baseUrl: '',// Only for usage with Amazon Cognito
    clientId: '01bc4ccf-e64f-4c20-952f-325aec4a5019', // For usage with AzureAD and Amazon Cognito // INIT Add client ID of app registration
    consentScopes: ['api://74d5b741-4219-463e-9803-349edf6650e4/Api.Invoke'], // Only for usage with AzureAD // INIT Add scope exposed API of app registration
    postLogoutRedirectUri: 'https://portal.4sourcing.de/', // Only for usage with AzureAD // INIT Add redirect URI after logout
    provider: 'AzureAD', // INIT Set to 'Amazon Cognito' or 'AzureAD'
    redirectUri: 'https://portal.4sourcing.de/', // For usage with AzureAD and Amazon Cognito // INIT Add redirect URI after login
    cloudDiscoveryMetadata: '', // INIT Add cloud discovery metadata - Leave empty if default request should be made
    authorityMetadata: '', // INIT Add authority metadata - Leave empty if default request should be made
  },
  regularExpressions: {
    websiteBaseUrl:
      '(https?://)?([\\da-zäöüß.-]+)\\.([a-zäöüß.]{2,6})[/\\wäöüß .-]*/?',
  },
  searchActive: true,
  shopActive: true,
  sentryActive: true
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
