import {PermissionsService} from '@/core/session/state/permissions/permissions.service';
import {SessionQuery} from '@/core/session/state/session.query';
import {SupplierProduct} from '@/shop/supplier-product/state/supplier-product.model';
import {SUPPLIER_PRODUCTS_PAGINATOR} from '@/shop/supplier-product/state/supplier-products.paginator';
import {SupplierProductsQuery} from '@/shop/supplier-product/state/supplier-products.query';
import {SupplierProductsService} from '@/shop/supplier-product/state/supplier-products.service';
import {SupplierProductsState} from '@/shop/supplier-product/state/supplier-products.store';
import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {PaginationResponse, PaginatorPlugin} from '@datorama/akita';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  isSupplierProductProposalAllowed = false;
  isLoadingSubject = new BehaviorSubject(false);

  supplierProductsPagination$: Observable<PaginationResponse<SupplierProduct>>;
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    public sessionQuery: SessionQuery,
    @Inject(SUPPLIER_PRODUCTS_PAGINATOR)
    private paginatorRef: PaginatorPlugin<SupplierProductsState>,
    private permissionsService: PermissionsService,
    private supplierProductsQuery: SupplierProductsQuery,
    private supplierProductsService: SupplierProductsService,
  ) {
  }

  ngOnInit() {
    this.initializePagination();

    this.permissionsService.checkIfUserHasOneOrMorePermissionTo([{
        model: 'supplierProduct',
        attribute: 'proposal',
        action: 'create'
      }]
    ).then(hasOneOrMorePermissions => {
      this.isSupplierProductProposalAllowed = hasOneOrMorePermissions;
    });

    // HINT: This is required to get supplier products with same base product
    this.isLoadingSubject.next(true); // Manual loading state because of multiple supplier product requests
    this.supplierProductsService.get().pipe(
      tap(() => this.isLoadingSubject.next(false))
    ).subscribe();
  }

  ngOnDestroy() {
    this.paginatorRef.clearPage(1);
    this.paginatorRef.destroy();
  }

  navigateToSupplierProductProposal() {
    this.supplierProductsService.navigateToSupplierProductProposal();
  }

  private initializePagination() {
    const paginatorRef$ = this.paginatorRef.pageChanges.pipe(
      switchMap((page) => this.paginatorRef.getPage(
        () => this.supplierProductsService.getPage(page, 4))
      ),
    );

    this.supplierProductsPagination$ = combineLatest([
      paginatorRef$,
      this.supplierProductsQuery.supplierProducts$,
    ]).pipe(
      map(([paginatorRef, supplierProducts]) => ({
        ...paginatorRef,
        data: this.mapExistingAndPaginatorRefSupplierProducts(paginatorRef.data, supplierProducts),
      })),
    );
  }

  private mapExistingAndPaginatorRefSupplierProducts(supplierProductsFromPaginatorRef: SupplierProduct[], supplierProducts: SupplierProduct[]) {
    return supplierProductsFromPaginatorRef.map(supplierProductFromPaginator => {
      const existingSupplierProduct = supplierProducts.find(supplierProduct => supplierProduct.id === supplierProductFromPaginator.id);

      return existingSupplierProduct &&
      new Date(existingSupplierProduct.updated_at) >= new Date(supplierProductFromPaginator.updated_at)
        ? {
          ...supplierProductFromPaginator,
          ...existingSupplierProduct, // HINT: Destructure this after pagination data to get latest state of favorites
        }
        : supplierProductFromPaginator;
    });
  }
}
