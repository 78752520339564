import {Injectable} from '@angular/core';
import {combineQueries, ID, QueryEntity} from '@datorama/akita';
import {map} from 'rxjs/operators';
import {ProductCategoriesQuery} from './product-categories/product-categories.query';
import {SupplierProductsState, SupplierProductsStore} from './supplier-products.store';
import {ProductCategory} from './product-categories/product-category.model';
import {DqnFilterCategory} from '@dqn/components-library/filter';
import {SupplierProduct} from './supplier-product.model';

@Injectable({providedIn: 'root'})
export class SupplierProductsQuery extends QueryEntity<SupplierProductsState> {
  isLoading$ = this.selectLoading();

  supplierProducts$ = this.selectAll();

  supplierProductsWithoutDuplicates$ = this.supplierProducts$.pipe(
    map(supplierProducts => supplierProducts.reduce((filteredSupplierProducts, supplierProduct) =>
      // Filter out supplier products with same product
      filteredSupplierProducts.some(filteredSupplierProduct => filteredSupplierProduct.product?.id === supplierProduct.product?.id)
        ? filteredSupplierProducts
        : [
          ...filteredSupplierProducts,
          supplierProduct,
        ], [] as SupplierProduct[])
    ),
  );

  supplierProductsForActiveProductCategory$ = combineQueries([
    this.productCategoriesQuery.selectActive(),
    this.selectAll(),
  ]).pipe(
    map(([productCategory, supplierProducts]) =>
      supplierProducts.filter((supplierProduct) => supplierProduct.product?.product_category_id === productCategory?.id)
    ),
  );

  hasSupplierProductsForActiveProductCategory$ = this.supplierProductsForActiveProductCategory$.pipe(
    map(supplierProducts => supplierProducts?.length > 0),
  );

  supplierProductsForActiveProductCategoryWithoutDuplicates$ = this.supplierProductsForActiveProductCategory$.pipe(
    map(supplierProducts => supplierProducts.reduce((filteredSupplierProducts, supplierProduct) =>
      // Filter out supplier products with same product
      filteredSupplierProducts.some(filteredSupplierProduct => filteredSupplierProduct.product?.id === supplierProduct.product?.id)
        ? filteredSupplierProducts
        : [
          ...filteredSupplierProducts,
          supplierProduct,
        ], [] as SupplierProduct[])),
  );

  areSupplierProductsForActiveProductCategoryLoading$ = combineQueries([
    this.hasSupplierProductsForActiveProductCategory$,
    this.isLoading$,
  ]).pipe(
    map(([hasSupplierProductsForActiveCategory, isLoading]) => isLoading && !hasSupplierProductsForActiveCategory),
  );

  activeSupplierProduct$ = this.selectActive();
  activeSupplierProduct = this.getActive();
  activeSupplierProductProductAttributes$ = this.selectActive(
    ({product}) => product?.product_attributes
  );

  hasSupplierProducts$ = this.supplierProducts$.pipe(
    map(supplierProducts => supplierProducts?.length > 0),
  );

  areSupplierProductsLoading$ = combineQueries([
    this.hasSupplierProducts$,
    this.isLoading$,
  ]).pipe(
    map(([hasSupplierProducts, isLoading]) => isLoading && !hasSupplierProducts),
  );

  favoriteSupplierProducts$ = this.supplierProducts$.pipe(
    map(supplierProducts => supplierProducts.filter(supplierProduct => supplierProduct.is_favorite)),
  );
  hasFavoriteSupplierProducts$ = this.favoriteSupplierProducts$.pipe(
    map(supplierProducts => supplierProducts?.length > 0),
  );

  areSupplierProductFavoritesLoading$ = combineQueries([
    this.hasFavoriteSupplierProducts$,
    this.isLoading$,
  ]).pipe(
    map(([hasFavoriteSupplierProducts, isLoading]) => isLoading && !hasFavoriteSupplierProducts),
  );

  availableCategoryFiltersForFavoriteSupplierProductsAsDqnFilterCategory$ = this.favoriteSupplierProducts$.pipe(
    map(supplierProducts => supplierProducts
      .filter(supplierProduct => supplierProduct.product?.product_category_id && supplierProduct.product?.product_category)
      .reduce((categories, {product}) =>
        categories.some(category => category.id === product?.product_category_id)
          ? categories
          : [
            ...categories,
            product?.product_category,
          ], [] as ProductCategory[])
    ),
    map(categories => ([
      {
        title: 'Kategorie',
        filterObjectKey: 'activeCategories',
        multiselect: true,
        multiselectAllLabel: 'Alle',
        options: categories.map(({id, display_name}) => ({
          label: display_name,
          value: id,
        }))
      } as DqnFilterCategory,
    ]))
  );

  constructor(protected store: SupplierProductsStore, private productCategoriesQuery: ProductCategoriesQuery) {
    super(store);
  }

  getById(id: number) {
    return this.selectEntity(id);
  }

  // TODO Optimize performance (create API endpoint for all supplier products filtered by product category id)
  getSupplierProductsByProductCategoryId(id: ID) {
    return this.selectAll({
      filterBy: (supplierProduct) => supplierProduct.product?.product_category_id === id
    });
  }

  selectSupplierProductsWithSameBaseProduct(productId: number) {
    return this.supplierProducts$.pipe(
      map(supplierProducts => supplierProducts.filter(({product}) => product?.id === productId)),
    );
  }
}
